<template>
    <div class="Profile-home-works home-works">
        <el-radio-group v-model="status" @change="getHomeworks(currentPage)">
            <el-radio-button label="1">{{ $t('profile.home-works.current') }}</el-radio-button>
            <el-radio-button label="2">{{ $t('profile.home-works.lost') }}</el-radio-button>
            <el-radio-button label="3">{{ $t('profile.home-works.checking') }}</el-radio-button>
            <el-radio-button label="4">{{ $t('profile.home-works.checked') }}</el-radio-button>
        </el-radio-group>
        <div class="home-works__header">
            <form-group>
                <el-select
                    @change="getHomeworks(currentPage)"
                    clearable
                    :placeholder="$t('profile.home-works.all-subjects')"
                    v-model="subject">
                    <el-option
                        v-for="item in subjects"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </form-group>
            <div class="home-works__all-tasks d-none">
                {{$t('profile.home-works.all-tasks')}} 86
            </div>
        </div>
        <div class="home-works__section">
            <div class="home-works__section-title">
                {{$t('profile.home-works.current')}} 8
            </div>
            <div class="home-works__section-rows">
                <div class="row" v-loading="loadingService">
                    <div class="col-lg-3 col-md-6" v-for="homework in homeworks">
                        <div class="hw-card" :class="{
                            'hw-card--pink': homework.status === 2,
                            'hw-card--yellow': homework.status === 3,
                            'hw-card--green': homework.status === 4
                        }">
                            <div class="hw-card__header">
                                <div class="hw-card__title">
                                    {{ homework.subject_name }}
                                </div>
                                <div class="hw-card__btns">
                                    <button class="hw-card__btn">
                                        <img v-if="homework.topic_content_type_id === 1" src="/images/profile/edit-icon.svg" width="18" height="18" alt="">
                                        <img v-if="homework.topic_content_type_id === 2" src="/images/profile/write-icon.svg" width="18" height="18" alt="">
                                        <img v-if="homework.topic_content_type_id === 3" src="/images/profile/check-icon.svg" width="18" height="18" alt="">
                                    </button>
                                    <el-popover
                                        placement="bottom"
                                        width="264"
                                        trigger="click"
                                        :popper-class="getPopperClass(homework.status)">
                                        <div class="info-popper__title">
                                            {{$t('profile.home-works.popper-title')}}
                                        </div>
                                        <div class="info-popper__status">
                                            {{$t('profile.home-works.popper-status-' + homework.status)}}
                                        </div>
                                        <div class="info-popper__text">
                                            {{$t('profile.home-works.popper-subject')}}{{ homework.subject_name }}
                                        </div>
                                        <div class="info-popper__text" v-if="homework.topic.name">
                                            {{$t('profile.home-works.popper-theme')}}{{ homework.topic.name }}
                                        </div>
                                        <div class="info-popper__text" v-if="homework.topic_content_type">
                                            {{$t('profile.home-works.popper-type')}}{{ homework.topic_content_type }}
                                        </div>
                                        <div class="info-popper__text">
                                            {{$t('profile.home-works.popper-teacher')}}{{ homework.teacher.last_name }} {{ homework.teacher.first_name }} {{ homework.teacher.patronymic }}
                                        </div>
                                        <div class="info-popper__date">
                                            {{$t('profile.home-works.popper-date')}}{{ homework.created_at }}
                                        </div>
                                        <el-button slot="reference" class="hw-card__btn">
                                            <img src="/images/profile/info-icon.svg" width="18" height="18" alt="">
                                        </el-button>
                                    </el-popover>
                                </div>
                            </div>
                            <div class="hw-card__body" @click="$router.push({ name: 'homework-detail', params: { id: homework.schedule_id }})">
                                <div class="hw-card-body-pencil" v-if="homework.status === 1"></div>
                                <div class="hw-card-body-pencil-red" v-if="homework.status === 2"></div>
                                <div class="hw-card-body-pencil-yellow" v-if="homework.status === 3"></div>
                                <div class="hw-card__result" v-if="homework.status === 4">
                                    {{ homework.score }}
                                </div>
                            </div>
                            <div class="hw-card__footer">
                                <div>
                                    {{ $t('profile.home-works.pass-till') }} {{ moment(homework.deadline).format('DD-MM-YY') }}
                                </div>
                                <div v-if="homework.status === 4">
                                    {{ $t('profile.home-works.passed') }} {{ homework.passed }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-pagination
            v-if="total > perPage"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            last-number
            first-number
            @change="getHomeworks"
            hide-goto-end-buttons
            align="center"
            next-text=">"
            prev-text="<">
        </b-pagination>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loadingTimeout: false,
                loadingService: false,
                status: '1',
                total: 0,
                perPage: 0,
                currentPage: 1,
                subject: '',
                activeItem: 'current',
                subjects: [],
                homeworks: []
            }
        },
        mounted() {
            this.getHomeworks(1)
        },
        methods: {
            getHomeworks(page) {
                this.loadingService = true;
                this.loadingTimeout = setTimeout(() => {
                    this.loadingService = false;
                }, 5000);
                let subjectId = this.subject
                let status = this.status
                this.$http.get(`${window.API_ROOT}/api/student/homeworks?page=${page}&subject=${subjectId}&status=${status}`)
                    .then((res) => {
                        this.homeworks = res.body.data
                        this.subjects = res.body.subjects
                        this.total = res.body.total
                        this.perPage = res.body.perPage
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
                    .catch(() => {
                        this.loadingService = false
                        window.clearTimeout(this.loadingTimeout)
                    })
            },
            getPopperClass(id) {
                switch (id) {
                    case 2:
                        return 'info-popper info-popper--pink'
                    case 3:
                        return 'info-popper info-popper--yellow'
                    case 4:
                        return 'info-popper info-popper--green'
                    default:
                        return 'info-popper'
                }
            },
        },
    }
</script>
<style lang="less" scoped>
/deep/ .el-loading-mask{
    background-color: transparent !important;
}
.Profile-home-works {
    color: #303030;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  max-width: 100%;
    width: 100%;
    // margin: 0 auto;
}
.el-radio-group {
    width: 100%;
}
.el-radio-group .el-radio-button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #473F95;
    width: 25%;
}
/deep/ .el-radio-button__inner{
    width: 100%;
    background: none;
    color: #473F95;
    border-color: #473F95;
}
/deep/ .is-active .el-radio-button__inner:hover {
    color: #fff !important;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner{
    border-color: #473F95
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #473F95;
    border-color: #473F95;
    box-shadow: -1px 0 0 0 #473f95;
}
/deep/ .el-radio-button__inner:hover {
    color: #473F95;
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 5px 0 0 5px;
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 5px 5px 0;
}
/deep/ .el-input__inner {
    min-width: 260px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 4px;
}
.el-select-dropdown__item.selected {
    color: #473F95;
}
/deep/ .el-select .el-input__inner:focus {
    border-color: #E0E0E0;
}
/deep/ .el-input__inner::placeholder {
    color: #303030;
    font-size: 13px;
}
.home-works__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 72px;
    margin-top: 32px;
}
.home-works__header .form-group {
    margin-bottom: 0;
}
.home-works__section-title {
    margin-bottom: 20px;
    display: none;
}
.hw-card {
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    min-width: 220px;
    min-height: 202px;
    position: relative;
}
.hw-card__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 12px 0 12px;
    align-items: baseline;
}
.hw-card__btns {
    display: flex;
    flex-direction: row;
}
.hw-card__btns .hw-card__btn:last-child {
    margin-left: 15px;
}
.hw-card__btn {
    border: none;
    background-color: #fff;
    padding: 0;
    display: flex;
    align-items: center;
}
.hw-card__body {
    background: url("/images/profile/bg-purple.svg") no-repeat top;
    background-size: cover;
    height: calc(202px - 100px);
    position: absolute;
    bottom: 67px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hw-card__footer {
    color: #fff;
    background: #473F95;
    border-radius: 0 0 10px 10px;
    padding: 24px 12px;
    font-size: 16px;
    line-height: 19px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
.hw-card__title {
    max-width: 105px;
}

.home-works__section-rows .row {
    margin-bottom: 30px;
}
.home-works__section-rows .row:last-child {
    margin-bottom: 54px;
}
.hw-card-body-pencil {
    background-color: white;
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 75px;
    background-image: url("/images/pen-homework-teacher.svg");
}

.hw-card-body-pencil-red {
    background-color: white;
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 75px;
    background-image: url("/images/pen-homework-teacher-red.svg");
}

.hw-card-body-pencil-yellow {
    background-color: white;
    height: 75px;
    width: 75px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 75px;
    background-image: url("/images/pen-homework-teacher-yellow.svg");
}

.hw-card--pink .hw-card__body {
    background-image: url("/images/profile/bg-pink.svg");
}
.hw-card--pink .hw-card__footer {
    background: #D23168;
}
.hw-card--yellow .hw-card__body {
    background-image: url("/images/profile/bg-yellow.svg");
}
.hw-card--yellow .hw-card__footer {
    background: #DE9A06;
}
.hw-card--green .hw-card__body {
    background-image: url("/images/profile/bg-green.svg");
}
.hw-card--green .hw-card__footer {
    background: #55953F;
}
.hw-card--green .hw-card__footer {
    padding: 12px;
    min-height: 67px;
}
.home-works__more {
    text-decoration: underline;
    border: none;
    background-color: transparent;
    margin-bottom: 20px;
}
.hw-card__result {
    color: #55953F;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
}
.info-popper {
    padding: 12px;
    font-family: 'Roboto', sans-serif;
}
.info-popper__title {
    color: #473F95;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
}
.info-popper__status {
    color: #473F95;
    padding: 13px;
    font-size: 18px;
    line-height: 21px;
    background: #EEEAFB;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.info-popper__text {
    padding-bottom: 15px;
    color: #473F95;
    font-size: 16px;
    line-height: 19px;
    word-wrap: break-word;
}
.info-popper__date {
    color: #473F95;
    font-size: 18px;
    line-height: 21px;
    padding-top: 10px;
    border-top: 1px solid #473F95;
}
.info-popper--yellow .info-popper__date{
    color: #DE9A06;
    border-top: 1px solid #DE9A06;
}
.info-popper--yellow .info-popper__status {
    color: #DE9A06;
    background: #FFF7CE;
}
.info-popper--green .info-popper__date{
    color: #55953F;
    border-top: 1px solid #55953F;
}
.info-popper--green .info-popper__status {
    color: #55953F;
    background: #F4FBEA;
}
.info-popper--pink .info-popper__date{
    color: #D23168;
    border-top: 1px solid #D23168;
}
.info-popper--pink .info-popper__status {
    color: #D23168;
    background: #FBEAEA;
}
.home-works-tabs {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.nav-tabs.home-works-tabs {
    border-bottom: none;
}
.home-works-tabs .home-works-tabs__item {
    width: 25%;
}
.home-works-tabs__title {
    // width: 248px;
    text-align: center;
    border: 1px solid #473F95;
    box-sizing: border-box;
    padding: 8px 24px;
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border-radius: 0;
    border-right: none;
    display: block;
}
.home-works-tabs__title.active {
    background: #473F95;
    border: 1px solid #473F95;
    color: #FFFFFF;
}
.home-works-tabs__title:hover {
    border: 1px solid #473F95;
    border-right: none;
}
.home-works-tabs .home-works-tabs__item:first-child .home-works-tabs__title {
    border-radius: 5px 0 0 5px;
} 

.home-works-tabs .home-works-tabs__item:last-child .home-works-tabs__title {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #473F95;
} 
.home-works-tabs .home-works-tabs__item:last-child .home-works-tabs__title:hover {
    border: 1px solid #473F95;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 13px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}

@media (max-width: 1200px) {
    .hw-card {
        min-width: 180px;
    }
    .hw-card__btns .hw-card__btn:last-child {
        margin-left: 8px;
    }
    .home-works__header {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
@media (max-width: 991px) {
    .home-works__all-tasks {
        margin-bottom: 16px;
    }
    /deep/ .home-works__header .form-group,
    /deep/ .home-works__header .form-group .el-select {
        width: 100%;
    }
    .hw-card {
        // background-size: 100% 95%;
        max-width: 220px;
        margin: 0 auto 30px;
    }
    .home-works__section-rows .row:last-child {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .pagination {
        margin-bottom: 38px;
    }
    .info-popper__title {
        font-size: 16px;
        line-height: 19px;
    }
    .info-popper__status {
        font-size: 16px;
        line-height: 19px;
    }
    .info-popper__text {
        font-size: 14px;
        line-height: 16px;
    }
    .info-popper__date {
        font-size: 14px;
        line-height: 16px;
    }
}
@media (max-width: 767px) {
    .home-works-tabs .home-works-tabs__item {
        width: auto;
    }
    .home-works-tabs__title {
        border: 1px solid #473F95;
        border-radius: 0;
        padding: 14px 14px;
    }
    .home-works-tabs .home-works-tabs__item:first-child .home-works-tabs__title,
    .home-works-tabs .home-works-tabs__item:nth-child(2) .home-works-tabs__title,
    .home-works-tabs .home-works-tabs__item:nth-child(3) .home-works-tabs__title,
    .home-works-tabs .home-works-tabs__item:last-child .home-works-tabs__title {
        border-radius: 12px;
        margin-right: 12px;
    }
    .home-works-tabs .home-works-tabs__item:last-child .home-works-tabs__title {
        margin-right: 0;
    }
    .home-works-tabs {
        justify-content: flex-start;
    }
    .Profile-home-works {
        width: 100%;
    }
    /deep/ .pagination {
       padding-top: 0;
       margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
    .home-works-tabs {
        display: flex;
        overflow-x: auto;
        margin-right: -12px;
        padding-right: 12px;
        margin-left: -12px;
        padding-left: 12px;
    }
    .home-works-tabs__title {
        white-space: nowrap;
        padding: 9px 10px;
        font-size: 14px;
        line-height: 22px;
    }
    .home-works__tabs-header {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 25px;
    }
    .home-works__header {
        margin-top: 15px;
        margin-bottom: 25px;
    }
    .home-works__section-title {
        display: block;
        color: #D23168;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 24px;
    }
    /deep/ .el-radio-group {
        display: flex;
        overflow-x: auto;
    }
    /deep/ .el-radio-button:first-child .el-radio-button__inner,
    /deep/ .el-radio-button__inner,
    /deep/ .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 12px;
        border: 1px solid #473F95;
    }
    /deep/ .el-radio-group .el-radio-button {
        width: 100%;
        margin-right: 12px;
    }
    /deep/ .el-radio-group .el-radio-button:last-child {
        margin-right: 0;
    } 
}

</style>